import React, { FunctionComponent, ReactElement } from 'react';
import { FiPlusCircle } from 'react-icons/fi';

interface IButtonProps {
  type?: 'primary' | 'secondary' | 'danger';
  onClick?: () => void;
  colour?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  block?: boolean;
}

const Button: FunctionComponent<IButtonProps> = props => {
  let color;

  switch (props.type) {
    case 'primary':
      color = 'bg-teal-700 hover:bg-teal-600 text-teal-100 hover:text-teal-100';
      break;
    case 'secondary':
      color = 'bg-transparent text-teal-600 hover:text-teal-700';
      break;

    case 'danger':
      color = 'bg-red-600 hover:bg-red-500 text-white';
      break;
    default:
      color = 'bg-gray-300 hover:bg-gray-400 text-teal-800 hover:text-teal-900';
      break;
  }

  return (
    <button
      className={`${color} font-bold py-2 pr-4 pl-4 my-2 mx-1 rounded transition-all ${props.block &&
        'w-full'}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <div className="flex items-center text-center justify-center">
        {props.icon && <div className="mr-4">{props.icon}</div>}
        {props.children}
      </div>
    </button>
  );
};

Button.defaultProps = {
  colour: 'teal',
  disabled: false,
};

export default Button;
