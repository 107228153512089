import React, { useEffect, useState } from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import _ from 'underscore';
import OnCardItem from '../OnCardItem';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Button from 'components/Button';
import { FiCompass, FiCircle, FiPlusCircle } from 'react-icons/fi';

interface IData {
  _id?: string;
  name?: string;
  deletedAt?: string;
}

interface IFormProps {
  placeholder?: string;
  data: IData[];
  dbKey: string;
  loading?: boolean;
  onAddItem?: (object) => void;
  reloadData?: () => void;
  project: string;
  disabled?: boolean;
}

const UPDATE = {
  activity: loader('../../mutations/Activity/UPDATE_BY_ID.graphql'),
  channel: loader('../../mutations/Channel/UPDATE_BY_ID.graphql'),
  partner: loader('../../mutations/Partner/UPDATE_BY_ID.graphql'),
  persona: loader('../../mutations/Persona/UPDATE_BY_ID.graphql'),
  problem: loader('../../mutations/Problem/UPDATE_BY_ID.graphql'),
  relationship: loader('../../mutations/Relationship/UPDATE_BY_ID.graphql'),
  resource: loader('../../mutations/Resource/UPDATE_BY_ID.graphql'),
  revenue: loader('../../mutations/Revenue/UPDATE_BY_ID.graphql'),
  segment: loader('../../mutations/Segment/UPDATE_BY_ID.graphql'),
  structure: loader('../../mutations/Structure/UPDATE_BY_ID.graphql'),
  value: loader('../../mutations/Value/UPDATE_BY_ID.graphql'),
};

const CREATE = {
  activity: loader('../../mutations/Activity/CREATE_ONE.graphql'),
  channel: loader('../../mutations/Channel/CREATE_ONE.graphql'),
  partner: loader('../../mutations/Partner/CREATE_ONE.graphql'),
  persona: loader('../../mutations/Persona/CREATE_ONE.graphql'),
  problem: loader('../../mutations/Problem/CREATE_ONE.graphql'),
  relationship: loader('../../mutations/Relationship/CREATE_ONE.graphql'),
  resource: loader('../../mutations/Resource/CREATE_ONE.graphql'),
  revenue: loader('../../mutations/Revenue/CREATE_ONE.graphql'),
  segment: loader('../../mutations/Segment/CREATE_ONE.graphql'),
  structure: loader('../../mutations/Structure/CREATE_ONE.graphql'),
  value: loader('../../mutations/Value/CREATE_ONE.graphql'),
};

const REMOVE = {
  activity: loader('../../mutations/Activity/REMOVE_ONE.graphql'),
  channel: loader('../../mutations/Channel/REMOVE_ONE.graphql'),
  partner: loader('../../mutations/Partner/REMOVE_ONE.graphql'),
  persona: loader('../../mutations/Persona/REMOVE_ONE.graphql'),
  problem: loader('../../mutations/Problem/REMOVE_ONE.graphql'),
  relationship: loader('../../mutations/Relationship/REMOVE_ONE.graphql'),
  resource: loader('../../mutations/Resource/REMOVE_ONE.graphql'),
  revenue: loader('../../mutations/Revenue/REMOVE_ONE.graphql'),
  segment: loader('../../mutations/Segment/REMOVE_ONE.graphql'),
  structure: loader('../../mutations/Structure/REMOVE_ONE.graphql'),
  value: loader('../../mutations/Value/REMOVE_ONE.graphql'),
};

const CanvasForm = (props: IFormProps) => {
  const [name, setName] = useState('');
  const { dbKey } = props;

  const [data, setData] = useState([]);

  const [updateAction] = useMutation(UPDATE[dbKey]);
  const [createAction] = useMutation(CREATE[dbKey]);
  const [removeAction] = useMutation(REMOVE[dbKey]);

  useEffect(() => {
    setData(props.data);
    return () => {
      setData([]);
    };
  }, [props.data]);

  const addItemsToList = async () => {
    const record = {
      description:
        'Vestibulum ut dolor risus. Sed accumsan commodo laoreet. Maecenas sit amet libero ipsum. Cras convallis nisi eu augue commodo sodales. Integer est ligula, molestie sit amet orci at.',
      name: name,
      project: props.project,
    };
    const oldData = [...data];
    try {
      setName('');
      setData([...data, record]);

      const { data: createdData } = await createAction({
        variables: { record: record },
      });
      const key = `${dbKey}CreateOne`;
      const { record: createdRecord } = createdData[key];
      const listOFData = [...data, createdRecord];

      setData(listOFData);

      // @ts-ignore
      toastedAnalytics.emit('addItem');
    } catch (error) {
      setData(oldData);
      console.error(error);
    }
  };

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
  };

  const onRemove = async (id: string) => {
    try {
      const oldData = [...data];
      const filteredData = data.filter(i => i._id !== id);
      try {
        setData(filteredData);
        await removeAction({ variables: { id } });
        // @ts-ignore
        toastedAnalytics.emit('removeItem');
      } catch (e) {
        setData(oldData);
      }
      // props.reloadData();
    } catch (error) {
      console.error(error);
    }
  };

  const onUpdate = (id: string, data: object) => {
    const record = {
      ...data,
      _id: id,
    };

    try {
      updateAction({ variables: { record } });
      // @ts-ignore
      toastedAnalytics.emit('updatedItem');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="px-4">
      <motion.ul initial="visible" animate="visible" variants={list}>
        {!props.loading &&
          data &&
          data.length > 0 &&
          data
            .filter(i => !i.deletedAt)
            .map((info, i) => {
              return (
                <OnCardItem
                  key={info._id || i}
                  name={info.name || ''}
                  onRemove={onRemove}
                  onUpdate={onUpdate}
                  {...info}
                />
              );
            })}
      </motion.ul>

      <form>
        <input
          disabled={props.disabled}
          onChange={e => setName(e.target.value)}
          className="transition-all transition-ease-in-out bg-gray-200 hover:text-gray-900 appearance-none border-2 border-gray-200 rounded py-2 text-gray-600 leading-tight focus:outline-none focus:bg-gray-200 focus:border-teal-600 text-center font-bold w-full mt-8"
          type="text"
          placeholder={props.placeholder}
          value={name}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
              return addItemsToList();
            }
          }}
        />
      </form>
      {name && (
        <div className="flex justify-center mt-4 text-center">
          <Button
            block
            icon={<FiPlusCircle />}
            type="primary"
            onClick={addItemsToList}
          >
            Add
          </Button>
        </div>
      )}
    </div>
  );
};

const WrappedForm = React.memo(CanvasForm);

export default CanvasForm;
