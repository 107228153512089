import React, { useState, useEffect } from 'react';

import Card from '../../components/Card';
import CanvasForm from '../../components/CanvasForm';
import Title from '../../components/Title';
import { IUser, IBusinessModelCardsType } from '../../types';
import { useQuery } from '@apollo/react-hooks';

import { loader } from 'graphql.macro';
import Button from 'components/Button';
import { BMC } from './CanvasGrid';
import { pdf } from '@react-pdf/renderer';
import { useParams, Redirect } from 'react-router';
import { motion } from 'framer-motion';
import WizardScene from './WizardScene';
import { saveAs } from 'file-saver';
import 'react-toggle/style.css';
import './CanvasScene.css';
import HeaderCard from 'components/HeaderCard';
const basePath = '/images';

// const description: string =
// 'The business model canvas allows you get a big picture overview of the main aspects of your business. There is no need to be too precious about getting the information right first time. This is a living page, and will change with your business.';

interface IProjectVars {
  code: string;
}

interface IProjectData {
  projectOne: IUser;
}

const GET_PROJECT = loader('./GET.graphql');

const Canvas: React.FC = () => {
  const { id } = useParams();

  const [isWizardMode, setIsWizardMode] = useState(false);
  const [businessModelCardsList, setBusinessModelCardsList] = useState<
    IBusinessModelCardsType[]
  >([]);

  const { loading, error, data, refetch } = useQuery<
    IProjectData,
    IProjectVars
  >(GET_PROJECT, {
    variables: { code: id },
  });

  useEffect(() => {
    const projectIds = JSON.parse(localStorage.getItem('projects'));

    const projectId = data && data.projectOne && data.projectOne._id;

    if (!projectId) {
      return;
    }

    if (projectIds && projectIds.length > 0) {
      const index = projectIds.findIndex(pid => pid === projectId);

      // Does not exist so create
      if (index < 0) {
        localStorage.setItem(
          'projects',
          JSON.stringify([...projectIds, projectId]),
        );
      }
    } else {
      localStorage.setItem('projects', JSON.stringify([projectId]));
    }
  }, [data]);

  const setCanvasData = (project): IBusinessModelCardsType[] => {
    const {
      partners = [],
      activities = [],
      resources = [],
      values = [],
      relationships = [],
      channels = [],
      segments = [],
      revenues = [],
      structures = [],
    } = project;

    const canvas: IBusinessModelCardsType[] = [
      {
        data: partners,
        dbKey: 'partner',
        description:
          'Key Partners are a list of other external companies/suppliers/parties you may need to achieve your key activities and deliver value to the customer.',
        imagePath: `${basePath}/partners.svg`,
        name: 'Key Partners',
        placeholder: 'An Awesome Partner',
        order: 7,
        helpDescription:
          'Key Partners are the relationships that you have with other business, governmental, or non-consumer entities that help your business model work. These can be the relationships that your company has with your suppliers, your manufacturers, business partners, etc. These partnerships that you will undoubtedly create will be forces that help your business succeed in areas that would be inefficient for you to do yourself. Example: You can partner with a manufacturer to produce a part of your business product that you yourself may not be able to manufacture. In return, you have a contract to pay for these parts that your partner has made you.',
        helpTitle: 'List partners that you need to run your business',
        examples: [
          'Community Start-up groups',
          'Local coworking space',
          'Advertising Agency',
        ],
      },
      {
        data: activities,
        dbKey: 'activity',
        description:
          'The key activities of your business product are the actions that your business undertakes to achieve the value proposition for your customers',
        imagePath: `${basePath}/logo.svg`,
        name: 'Key Activites',
        placeholder: 'A Key Activity',
        order: 5,
        helpDescription:
          'Your business model calls for a number of Key Activities. These are the most important actions your company must perform to operate successfully. Like Key Resources, they are required to create and offer a Value Proposition, reach markets, maintain Customer Relationships, and earn revenues. And like Key Resources, Key Activities differ depending on business model type. For software maker Microsoft, Key Activities include software development. For PC manufacturer Dell, Key Activities include supply chain management. For consultancy McKinsey, Key Activities include problem-solving.',
        helpTitle: 'What Key Activities do our Value Propositions require?',
        examples: ['Marketing', 'Logo Design', 'Training and Development'],
      },
      {
        data: resources,
        dbKey: 'resource',
        description:
          'What practical resources are needed to achieve the key activities (actions) of the business? this can include an office, car, and even people.',
        imagePath: `${basePath}/resources1.svg`,
        name: 'Key Resources',
        placeholder: 'A Key Resource',
        order: 6,
        helpDescription:
          'Every business model requires Key Resources. Your resources allow your enterprise to create and offer a Value Proposition, reach markets, maintain relationships with Customer Segments, and earn revenues. Different Key Resources are needed depending on the type of business model. A microchip manufacturer requires capital-intensive production facilities, whereas a microchip designer focuses more on human resources. Key resources can be physical, financial, intellectual, or human. Key resources can be owned or leased by the company or acquired from key partners.',
        helpTitle: 'What Key Resources do our Value Propositions require?',
        examples: ['Office', 'Customer Database', 'Developers'],
      },
      {
        data: values,
        dbKey: 'value',
        description:
          'Generally, value is exchanged from a customer for money when a problem is solved or a pain is relieved for them by your business.',
        imagePath: `${basePath}/value.svg`,
        name: 'Value Propositions',
        placeholder: 'A value you provide',
        order: 1,
        helpDescription:
          'It is important for a product to solve a problem in a unique way. The problem could be unworkable, unavoidable, urgent or underserved. On the other end of the spectrum, the need for your product or service could be blatant, critical, aspirational or latent. A blatant need, as the word suggests, is one openly voiced and expressed by the customer. A latent need, conversely, is one which the customer himself may not be aware of having. A critical need is an immediate need that the customer MUST have fulfilled.',
        helpTitle: 'Why do people buy your products?',
        examples: [
          'Experience / Reputation',
          'Customised Offering',
          'Simplify complicated processes',
        ],
      },
      {
        data: relationships,
        dbKey: 'relationship',
        description:
          'Customer relationships is defined as how a business interacts with its customers. do you meet with them in person? or over the phone? or is your business run online?',
        imagePath: `${basePath}/relationships.svg`,
        name: 'Relationships',
        placeholder: 'A relationship you have',
        order: 2,
        helpDescription:
          'Your company should clarify the type of relationship it wants to establish with each Customer Segment. Relationships are established through your different Channels. Relationships can range from personal to automated, from transactional to long-term, and can aim to acquire customers, retain customers, or boost sales (upselling). The type of Customer Relationships you put in place deeply influence the overall customer experience.',
        helpTitle:
          'What type of relationship does each of our Customer Segments expect us to establish and maintain with them?',
        examples: [
          'Personal Face to Face experience',
          'Self service online store',
        ],
      },
      {
        data: channels,
        dbKey: 'channel',
        description:
          'Channels are defined as the avenues through which your customer comes into contact with your business and becomes part of your sales cycle.',
        imagePath: `${basePath}/channel.svg`,
        name: 'Channels',
        placeholder: 'Channel Name',
        order: 3,
        helpDescription:
          'Your communication, distribution, and sales Channels comprise your companys interface with customers. Your Channels are customer touch points that play an important role in the customer experience.',
        helpTitle: 'How do you communicate with your Customer Segments?',
        examples: ['Website', 'Social Media', 'Retail Store'],
      },
      {
        data: segments,
        dbKey: 'segment',
        description:
          'Customer Segmenting is the practice of dividing a customer base into groups of individuals that are similar in specific ways, such as age, gender, interests and spending habits.',
        imagePath: `${basePath}/segments.svg`,
        name: 'Customer Segments',
        placeholder: 'Segment name',
        order: 0,
        helpDescription:
          'Your Customer Segments are the different groups of people or organizations your enterprise aims to reach and serve. This includes users who might not generate revenues, but which are necessary for the business model to work (e.g. users of Google’s free search engine are essential to their advertising-based business model, or doctors are usually an essential, but non revenue-generating part of a pharmaceutical company’s business model).',
        helpTitle: 'Who are your customers?',
        examples: [
          'Young entrepreneurs',
          'Parents of children under 3 years old',
          'Home owners with pets',
        ],
      },
      {
        data: revenues,
        dbKey: 'revenue',
        description:
          'Revenue Streams are defined as the way by which your business converts your Value Proposition or solution to the customer’s problem into financial gain.',
        imagePath: `${basePath}/revenue.svg`,
        name: 'Revenue',
        placeholder: 'Revenue name',
        order: 4,
        helpDescription:
          'If customers comprise the heart of your business model, Revenue Streams are its arteries. You must ask yourself, for what value is each Customer Segment truly willing to pay? Successfully answering that question allows your firm to generate one or more Revenue Streams from each Customer Segment. Each Revenue Stream may have different pricing mechanisms, such as fixed list prices, bargaining, auctioning, market dependent, volume dependent, or yield management.',
        helpTitle: 'For what value are our customers really willing to pay?',
        examples: ['Monthly Subscription', 'Advertising', 'Percentage of sale'],
      },
      {
        data: structures,
        dbKey: 'structure',
        description:
          'Your business cost structure is defined as the monetary cost of operating as a business. How much does it cost to achieve my businesses key activities?',
        imagePath: `${basePath}/cost.svg`,
        name: 'Cost Structures',
        placeholder: 'Cost name',
        order: 8,
        helpDescription:
          'This building block describes the most important costs incurred while operating under a particular business model. Creating and delivering value, maintaining Customer Relationships, and generating revenue all incur costs. Such costs can be calculated relatively easily after defining Key Resources, Key Activities, and Key Partnerships. Some business models, though, are more cost-driven than others. So-called “no frills” airlines, for instance, have built business models entirely around low-Cost Structures.',
        helpTitle:
          'What are the most important costs inherent in our business model?',
        examples: ['Salaries', 'stock'],
      },
    ];
    setBusinessModelCardsList(canvas);
    return canvas;
  };

  useEffect(() => {
    if (data && data.projectOne && !loading) {
      setCanvasData(data.projectOne);
    } else {
      const defualt = {
        partnerId: '',
        partners: [],
        activities: [],
        resources: [],
        values: [],
        relationships: [],
        channels: [],
        segments: [],
        revenues: [],
        structures: [],
      };
      setCanvasData(defualt);
    }
  }, [data, loading]);

  if (error) {
    return <Redirect to="/notfound" />;
  }

  if ((!data || !data.projectOne || !data.projectOne._id) && !loading) {
    return <Redirect to="/notfound" />;
  }

  const {
    projectOne = { _id: '', name: 'Business Model Canvas', partnerId: null },
  } = data || {};

  const { _id, name } = projectOne;

  const reloadData = () => {
    refetch({ code: id });
  };

  const downloadPDF = async () => {
    try {
      // @ts-ignore
      toastedAnalytics.emit('downloadPDF', {
        code: id,
      });
      const { data } = await refetch({ code: id });
      const items = setCanvasData(data.projectOne);
      const blob = await pdf(<BMC items={items} />).toBlob();
      saveAs(blob, `${name} - BMC`, { autoBom: true });
    } catch (error) {
      console.log('error', error);
    }
  };

  const switchMode = () => {
    // @ts-ignore
    toastedAnalytics.emit('toggleGuidedMode', {
      isWizardMode: !isWizardMode,
    });

    refetch({ code: id });
    setIsWizardMode(!isWizardMode);
  };

  const list = {
    visible: {
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.5,
      },
    },
    hidden: {
      transition: {
        when: 'afterChildren',
      },
    },
  };

  return (
    <div className="w-full flex flex-wrap content-center justify-center">
      <div className="w-full flex justify-center align-middle items-center h-48">
        <HeaderCard
          downloadPDF={downloadPDF}
          loading={loading}
          project={projectOne}
          code={id}
          toggleGuidedMode={switchMode}
          isGuidedMode={isWizardMode}
        />
      </div>
      <div className="w-full justify-end flex max-w-4xl mt-48"></div>
      {isWizardMode && (
        <WizardScene
          projectId={_id}
          businessModelCardsList={businessModelCardsList}
          loading={loading}
          switchMode={switchMode}
        />
      )}
      {!isWizardMode && (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={list}
          className="flex flex-wrap w-full justify-center mt-8"
        >
          {businessModelCardsList &&
            businessModelCardsList.length > 0 &&
            businessModelCardsList
              .sort((a, b) => a.order - b.order)
              .map(seg => {
                return (
                  <Card
                    key={seg.dbKey}
                    dbKey={seg.dbKey}
                    {...seg}
                    isEditable={false}
                  >
                    <CanvasForm
                      disabled={loading}
                      project={_id}
                      reloadData={reloadData}
                      loading={loading}
                      data={seg.data}
                      {...seg}
                    />
                  </Card>
                );
              })}
        </motion.div>
      )}
    </div>
  );
};

export default Canvas;
