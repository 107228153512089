import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
} from '@react-pdf/renderer';
import { IBase, IBusinessModelCardsType } from 'types';

const basePath = '/images';

interface ItemProps {
  item?: IBase;
}

const Item = (props: ItemProps) => {
  return (
    <View style={styles.itemContainer}>
      <Text style={styles.text}>{(props.item && props.item.name) || ''}</Text>
    </View>
  );
};

interface Iitem {
  title: string;
}

interface IPropsSegment {
  half?: boolean;
  title: string;
  items: IBase[];
}

const Segment = (props: IPropsSegment) => {
  return (
    <View style={props.half ? styles.halfSection : styles.fullSection}>
      <Text style={styles.title}>{props.title || ''}</Text>
      <View>
        {props.items.map(item => (
          <Item item={item} />
        ))}
      </View>
      <View></View>
    </View>
  );
};

const getItems = (items: IBusinessModelCardsType[], key: string): IBase[] => {
  const index = items.findIndex(item => item.dbKey === key);

  const { data } = items[index];

  return data;
};

interface IPropsBMC {
  items: IBusinessModelCardsType[];
}

export const BMC = (props: IPropsBMC) => (
  <Document>
    <Page size="A4" style={styles.body} orientation="landscape">
      <Segment
        title="Key Partners"
        items={getItems(props.items, 'partner')}
        half={false}
      />
      <View style={styles.halfContainer}>
        <Segment
          title="Key Activities"
          half
          items={getItems(props.items, 'activity')}
        />
        <Segment
          title="Key Resources"
          half
          items={getItems(props.items, 'resource')}
        />
      </View>
      <Segment
        title="Value Proposition"
        items={getItems(props.items, 'value')}
      />
      <View style={styles.halfContainer}>
        <Segment
          title="Customer Relationship"
          half
          items={getItems(props.items, 'relationship')}
        />
        <Segment
          title="Channels"
          half
          items={getItems(props.items, 'channel')}
        />
      </View>
      <Segment
        title="Customer Segments"
        items={getItems(props.items, 'segment')}
      />
      <View style={styles.bottom}>
        <Text style={styles.title}>Cost Structure</Text>

        <View style={styles.bottomContainer}>
          {getItems(props.items, 'structure').map(item => (
            <Item item={item} />
          ))}
        </View>
      </View>
      <View style={styles.bottom}>
        <Text style={styles.title}>Revenue Streams</Text>
        <View style={styles.bottomContainer}>
          {getItems(props.items, 'revenue').map(item => (
            <Item item={item} />
          ))}
        </View>
      </View>
      <View fixed>
        <Text style={styles.pageNumber}>Built by Up!</Text>
      </View>
    </Page>
  </Document>
);

// Font.register({
//   family: 'Source Sans Pro',
//   src:
//     'https://github.com/google/fonts/blob/master/ofl/sourcesanspro/SourceSansPro-Regular.ttf',
// });

const styles = StyleSheet.create({
  body: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  itemContainer: {
    width: '100%',
    maxWidth: 130,
    backgroundColor: '#718096',
    color: '#EDF2F7',
    padding: 10,
    borderRadius: 3,
    marginBottom: 5,
  },
  title: {
    fontSize: 10,
    textDecorationStyle: 'bold',
    textAlign: 'center',
    // fontFamily: 'Source Sans Pro',
    marginTop: 10,
    fontWeight: 800,
    color: '#2D3748',
    width: '100%',
    marginBottom: 10,
  },
  text: {
    fontSize: 8,
  },
  fullSection: {
    marginBottom: 0,
    padding: 10,
    textAlign: 'center',
    backgroundColor: '#EDF2F7',
    borderColor: '#4A5568',
    border: 1,
    width: '20%',
    height: '80%',
    justifyContent: 'space-between',
  },
  halfSection: {
    textAlign: 'center',
    borderColor: '#4A5568',
    backgroundColor: '#EDF2F7',
    border: 1,
    width: '100%',
    height: '50%',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: 10,
  },
  halfContainer: {
    flexDirection: 'column',
    width: '20%',
    height: '80%',
  },
  bottomContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    width: '100%',
  },
  bottom: {
    fontSize: 12,
    height: '20%',
    textAlign: 'center',
    backgroundColor: '#EDF2F7',
    borderColor: '#4A5568',
    border: '1',
    width: '50%',
    padding: 5,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 9,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'right',
    color: 'grey',
  },
});

interface IProps {
  items: IBusinessModelCardsType[];
}

const CanvasGrid = (props: IProps) => {
  return (
    <PDFViewer style={{ width: '100%', minHeight: '100vh' }}>
      <BMC items={props.items} />
    </PDFViewer>
  );
};

export default CanvasGrid;
