import React from 'react';
import HomeCard from 'components/HomeCard';

const NotFound: React.FC = () => {
  return (
    <div className="flex justify-center flex-wrap">
      <div className="text-center w-full mb-8">
        <h2 className="font-extrabold text-2xl text-gray-800">
          Sorry, that project could not be found
        </h2>
        <p className="text-gray-600">
          The project may have been removed, or the code you entered could be
          incorrect
        </p>
      </div>
      <HomeCard
        title="Try a new code"
        description="Join an already created canvas by entering your code below"
        placeholder="Code"
        hasCode
      />
    </div>
  );
};

export default NotFound;
