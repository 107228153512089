import React, { useState, useEffect } from 'react';

import { motion } from 'framer-motion';

import { FiTrash } from 'react-icons/fi';
import { RIEInput } from 'riek';
import _ from 'underscore';
import isTouchDevice from 'is-touch-device';

type Suggestion = {
  _id: string;
  name: string;
  domain: string;
  logo: string;
};
interface ICardProps {
  _id: string;
  name?: string;
  logo?: string;
  suggestions?: Suggestion[];
  onUpdate?: (id: string, item: {}) => void;
  onRemove?: (id: string) => void;
}

interface IEditValue {
  name: string;
  logo?: string;
  suggestions?: Suggestion[];
}

const OnCardItem = (props: ICardProps) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [name, setName] = useState(props.name);
  const [logo, setLogo] = useState(props.logo);
  const [suggestions, setSuggestions] = useState(props.suggestions || []);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    setSuggestions(props.suggestions);
  }, [props.suggestions]);

  const items = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -10 },
  };

  const update = (text: IEditValue) => {
    if (props.onUpdate) {
      try {
        props.onUpdate(props._id, text);
        setName(text.name);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const updateToSuggestion = (suggestion: Suggestion) => {
    const { logo, name } = suggestion;
    const record: IEditValue = {
      name,
      logo,
      suggestions: [],
    };

    if (props.onUpdate) {
      try {
        props.onUpdate(props._id, record);
        setName(record.name);
        setLogo(record.logo);
        setShowSuggestions(false);
        setSuggestions([]);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '-100%' },
  };

  return (
    <motion.li
      key={props._id}
      onMouseEnter={() => setShowOverlay(true)}
      onMouseLeave={() => setShowOverlay(false)}
      positionTransition
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0 }}
      variants={items}
      className={`p-2 text-center ${props._id &&
        'bg-gray-200'} font-bold mb-3 rounded flex justify-between items-middle text-gray-100 flex-wrap`}
    >
      <div className="w-full text-center flex justify-between">
        {logo ? (
          <img className="rounded w-6 h-6" src={logo} alt="logo" />
        ) : (
          <div />
        )}

        <RIEInput
          value={name}
          change={update}
          propName="name"
          className={props._id ? 'text-gray-800' : 'text-gray-500 text-center'}
          classInvalid="text-red-500"
          classEditing="bg-gray-200 font-bold text-center text-gray-600"
          validate={_.isString}
        />

        <motion.div className="transition-all hover:text-red-600 text-gray-500">
          <motion.button
            variants={variants}
            animate={showOverlay || isTouchDevice() ? 'open' : 'closed'}
            onClick={() => props.onRemove && props.onRemove(props._id)}
          >
            <FiTrash />
          </motion.button>
        </motion.div>
      </div>
      {suggestions && suggestions.length > 0 && (
        <div className="w-full">
          <button
            onClick={() => setShowSuggestions(!showSuggestions)}
            className="text-xs text-teal-500 cursor-pointer max-w-xs hover:underline mt-2"
          >
            {showSuggestions ? 'Hide' : 'Show'} Suggestions
          </button>
          {showSuggestions && (
            <ul className="text-gray-600 text-xs mt-2 mb-2">
              {suggestions.map(suggestion => {
                return (
                  <button
                    onClick={() => updateToSuggestion(suggestion)}
                    key={suggestion._id}
                    className="my-1 hover:text-gray-700 cursor-pointer transition-all bg-gray-300 hover:bg-gray-400 rounded p-2 w-full flex justify-between"
                  >
                    {suggestion.logo ? (
                      <img
                        className="rounded w-6"
                        src={suggestion.logo}
                        alt="logo"
                      />
                    ) : (
                      <div />
                    )}
                    {suggestion.name}
                    <div />
                  </button>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </motion.li>
  );
};

OnCardItem.defaultProps = {
  _id: '020292',
  name: '',
};

const WrappedOnCardItem = React.memo(OnCardItem);

export default WrappedOnCardItem;
