import React from 'react';

import Swiper from 'react-id-swiper';
import { isMobile } from 'react-device-detect';
import Loader from 'react-loader-spinner';

import Card from 'components/Card';
import CanvasForm from 'components/CanvasForm';

import 'swiper/css/swiper.css';
import { IBusinessModelCardsType } from 'types';
import Button from 'components/Button';

let params = {
  // centeredSlides: true,
  effect: 'coverflow',
  coverflowEffect: {
    rotate: 10,
    stretch: 0,
    depth: 100,
    modifier: 2,
    slideShadows: false,
  },
  grabCursor: false,
  spaceBetween: 30,

  navigation: {},
};

if (!isMobile) {
  params.navigation = {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  };
}

interface IProps {
  businessModelCardsList: IBusinessModelCardsType[];
  loading: boolean;
  projectId: string;
  switchMode: () => void;
}

const WizardScene: React.FC<IProps> = ({
  loading,
  businessModelCardsList,
  projectId,
  switchMode,
}) => {
  if (loading) {
    return (
      <Loader
        type="ThreeDots"
        color="#38b2ac"
        height={50}
        width={50}
        timeout={3000} //3 secs
      />
    );
  }

  const onFinish = () => {
    switchMode();
  };

  return (
    <div className="overflow-x-hidden text-center mt-8">
      <Swiper {...params}>
        {businessModelCardsList
          .sort((a, b) => a.order - b.order)
          .map((seg, i) => {
            return (
              <div className="flex justify-center text-center lg:text-left w-full flex-wrap">
                <div className="flex flex-col max-w-sm bg-gray-300 rounded p-8 shadow-inner">
                  <div>
                    <h2 className="text-teal-600 font-bold text-md -mb-2 tracking-wide">
                      Step {i + 1} - {seg.name}
                    </h2>
                    <h2 className="mt-4 text-gray-700 text-2xl font-bold mb-4">
                      {seg.helpTitle || ''}
                    </h2>
                    <p className="text-gray-700 text-md tracking-wider">
                      {seg.helpDescription || ''}
                    </p>
                  </div>
                  <div>
                    <h2 className="mt-8 text-gray-700 text-xl font-semibold mb-4">
                      Examples
                    </h2>
                    <ul className="text-center text-gray-100 font-semibold text-md">
                      {seg.examples.map(example => (
                        <li className="bg-gray-600 p-2 rounded my-4 transition-all capitalize tracking-wider">
                          {example}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <Card
                  key={seg.dbKey}
                  dbKey={seg.dbKey}
                  {...seg}
                  isEditable={false}
                >
                  <CanvasForm
                    project={projectId}
                    disabled={loading}
                    loading={loading}
                    data={seg.data}
                    {...seg}
                  />
                  {i === businessModelCardsList.length - 1 && (
                    <div className="flex mt-8">
                      <Button onClick={onFinish} block>
                        Finished
                      </Button>
                    </div>
                  )}
                </Card>
              </div>
            );
          })}
      </Swiper>
    </div>
  );
};

export default WizardScene;
