import React, { useState } from 'react';
import {
  FiDownload,
  FiColumns,
  FiCopy,
  FiTrash,
  FiTrash2,
} from 'react-icons/fi';
import Toggle from 'react-toggle';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import Loader from 'react-loader-spinner';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import Title from './Title';
import Button from './Button';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { Redirect, RouteComponentProps, useHistory } from 'react-router';

const copyText = 'Copy Canvas Link';

type Project = {
  _id?: string;
  name?: string;
  description?: string;
};

interface Props {
  downloadPDF: () => void;
  toggleGuidedMode: () => void;
  loading: boolean;
  project: Project;
  code: string;
  isGuidedMode: boolean;
}

const REMOVE_PROJECT = loader('../mutations/Project/REMOVE_ONE.graphql');

const HeaderCard: React.FC<Props> = ({
  loading,
  downloadPDF,
  project,
  code,
  toggleGuidedMode,
  isGuidedMode,
}) => {
  const [copyButtonText, setCopyButtonText] = useState(copyText);
  const [copyCodeText, setCopyCodeText] = useState();

  let history = useHistory();

  console.log('isGuidedMode', isGuidedMode);

  const [removeProject] = useMutation(REMOVE_PROJECT);

  const copyLink = () => {
    setCopyButtonText('Copied!');
    setTimeout(() => {
      setCopyButtonText(copyText);
    }, 2000);
  };

  const copyCode = () => {
    setCopyCodeText('Copied!');
    setTimeout(() => {
      setCopyCodeText(null);
    }, 2000);
  };

  const onRemoveProject = async () => {
    const { _id } = project;

    try {
      if (!_id) {
        throw new Error('No project found');
      }

      const { data } = await removeProject({
        variables: { id: _id },
        fetchPolicy: 'no-cache',
      });

      const projectIds: string[] | undefined = JSON.parse(
        localStorage.getItem('projects'),
      );

      const filteredProjectIds = projectIds.filter(id => id !== _id);

      localStorage.setItem('projects', JSON.stringify(filteredProjectIds));

      history.push('/');
    } catch (e) {
      alert(e);
    }
  };

  const { _id, name, description } = project;

  return (
    <div
      id="top-section"
      className="bg-teal-700 mb-8 p-10 text-white absolute flex flex-col items-center justify-center w-full"
      style={{ top: '56px' }}
    >
      <Title
        color="gray"
        _id={_id}
        title={name}
        description={description}
        disabled={loading}
      />

      <div
        id="buttons-section"
        className="flex justify-center w-full flex-wrap my-4 max-w-lg text-white"
      >
        <CopyToClipboard text={window.location.href} onCopy={() => copyLink()}>
          <Button icon={<FiCopy />}>{copyButtonText}</Button>
        </CopyToClipboard>

        <Button icon={<FiDownload />} onClick={() => downloadPDF()}>
          Download PDF
        </Button>
      </div>
      {code && (
        <div className="flex">
          <p className="text-teal-200">
            Your project code is{' '}
            <span className="text-white font-bold">
              {code} {''}
            </span>
          </p>
          <div className="ml-2 mr-2 text-lg cursor-pointer">
            <CopyToClipboard text={code} onCopy={() => copyCode()}>
              <FiCopy />
            </CopyToClipboard>
          </div>
          <span className="font-bold text-teal-100">{copyCodeText}</span>
        </div>
      )}

      <div className="flex items-center justify-center my-8">
        <label
          className="text-gray-200 font-bold tracking-wider z-10"
          htmlFor="overviewToggle"
        >
          Guided Mode:
        </label>
        <Toggle
          id="overviewToggle"
          className="mx-8"
          defaultChecked={isGuidedMode}
          checked={isGuidedMode}
          onChange={() => toggleGuidedMode()}
          icons={false}
        />
      </div>

      {/* <div className="flex justify-around items-baseline text-red-500 text-xs font-semibold mt-4">
        {!showRemove ? (
          <>
            <Button
              icon={<FiTrash2 />}
              type="secondary"
              onClick={() => setShowRemove(true)}
            ></Button>
          </>
        ) : (
          <div className="flex align-middle items-center justify-around">
            <p className="text-gray-600 mr-2">Are you sure?</p>
            <Button onClick={() => onRemoveProject()} type="danger">
              Delete my project
            </Button>
            <Button
              onClick={() => setShowRemove(false)}
              type="secondary"
              // className="ml-2 text-teal-600 hover:text-teal-500 font-semibold"
            >
              Cancel
            </Button>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default HeaderCard;
