import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import shortid from 'shortid';
import { Redirect, useHistory } from 'react-router';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { motion } from 'framer-motion';
import Button from './Button';
import { FiPlusCircle, FiKey } from 'react-icons/fi';
import { nanoid } from 'nanoid';

const CREATE_PROJECT = loader('../mutations/Project/CREATE_ONE.graphql');

interface Props {
  title: string;
  description: string;
  placeholder: string;
  hasCode?: boolean;
  code?: string;
  autoFocus?: boolean;
  partnerId?: string;
}

const HomeCard = (props: Props) => {
  const [code, setCode] = useState('');
  const [codeHasErrors, setCodeHasErrors] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [hasError, setHasError] = useState(false);
  let history = useHistory();

  const [createProject, { loading, error }] = useMutation(CREATE_PROJECT);

  useEffect(() => {
    if (props.code) {
      setCode(props.code);
    }
  }, [props.code]);

  const createNewProject = async () => {
    if (props.hasCode) {
      history.push(`/canvas/${code}`);

      return setShouldRedirect(shortid.isValid(code));
    }
    setIsLoading(true);
    setHasError(false);

    const gcode: string = nanoid(7).toUpperCase();

    const record = {
      name: name,
      code: gcode,
      partnerId: props.partnerId || null,
    };
    try {
      await createProject({ variables: { record } });

      setCode(gcode);
      setName('');
      history.push(`/canvas/${gcode}`);
    } catch (error) {
      setHasError(true);
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const validateAndSetCode = (code: string) => {
    setCode(code.toUpperCase());
    const isValid = shortid.isValid(code);

    setCodeHasErrors(!isValid);
  };

  if (shouldRedirect && !loading && !error) {
    return <Redirect to={`/canvas/${code}`} />;
  }

  const item = {
    visible: { opacity: 1, x: 0, scale: 1 },
    hidden: { opacity: 0, x: -50, scale: 0.97 },
  };

  const variants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <motion.div
      variants={item}
      className="max-w-sm bg-white rounded p-8 shadow-lg text-center w-full m-4 flex flex-col justify-end"
    >
      <div className="w-full max-h-full mb-8">
        <img
          alt="bmc"
          src={props.hasCode ? 'images/code.svg' : 'images/bmc.svg'}
        />
      </div>
      <div className="mb-4">
        <h1 className="font-bold text-gray-800 text-xl mt-4 mb-1 tracking-tight">
          {props.title}
        </h1>
        <p className="text-gray-600 text-sm tracking-wide">
          {props.description}
        </p>
      </div>
      <input
        autoFocus={props.autoFocus}
        type="text"
        disabled={isLoading}
        onChange={e =>
          props.hasCode
            ? validateAndSetCode(e.target.value)
            : setName(e.target.value)
        }
        className={`transition-all transition-ease-in-out ${
          codeHasErrors ? 'bg-red-300' : 'bg-gray-200'
        } hover:text-gray-900 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-600 leading-tight focus:outline-none focus:bg-gray-200 ${
          codeHasErrors ? 'focus:border-red-600' : 'focus:border-teal-600'
        } text-center mt-4 font-bold ${isLoading &&
          'text-gray-400'} ${props.hasCode && 'uppercase'}`}
        placeholder={props.placeholder}
        value={props.hasCode ? code : name}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            return createNewProject();
          }
        }}
      />

      {isLoading && !props.hasCode && (
        <div className="flex justify-around my-4 align-middle items-center">
          <p className="text-gray-500 font-bold">{`Generating ${name} canvas`}</p>
          <Loader
            type="ThreeDots"
            color="#38b2ac"
            height={50}
            width={50}
            timeout={3000} //3 secs
          />
        </div>
      )}
      {hasError && !props.hasCode && (
        <div className="flex justify-around my-4 align-middle items-center">
          <p className="text-gray-500 font-bold text-red-500 text-sm">{`Sorry something went wrong`}</p>
        </div>
      )}
      {name && (
        <motion.div
          className="w-full mt-4"
          initial="hidden"
          animate="visible"
          variants={variants}
        >
          <Button
            icon={<FiPlusCircle />}
            type="primary"
            onClick={() => createNewProject()}
          >
            Create Project
          </Button>
        </motion.div>
      )}

      {code && shortid.isValid(code) && (
        <div className="w-full mt-4">
          <Button
            icon={<FiKey />}
            type="primary"
            onClick={() => createNewProject()}
          >
            Join Project
          </Button>
        </div>
      )}
    </motion.div>
  );
};

HomeCard.defaultProps = {
  autoFocus: false,
};

export default HomeCard;
