import React, { useEffect, useState } from 'react';
import HomeCard from 'components/HomeCard';
import Button from 'components/Button';
import { useHistory } from 'react-router';
import { useParams } from 'react-router';
import { motion } from 'framer-motion';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import ObjectID from 'bson-objectid';

const GET_PROJECTS = loader('./GET.graphql');

interface Partner {
  logo: string;
  id: string;
}

const App = props => {
  const history = useHistory();
  const { partnerId } = useParams();
  const [partner, setPartner] = useState<Partner | undefined>();

  let projectIds: string[] | undefined = JSON.parse(
    localStorage.getItem('projects'),
  );

  const { loading, error, data = { projectMany: [] }, refetch } = useQuery(
    GET_PROJECTS,
    {
      variables: { ids: projectIds || [] },
    },
  );

  const projects = data.projectMany;

  useEffect(() => {
    const partners: Partner[] = [
      { id: 'startupclub', logo: '/partnerlogos/startupclub.png' },
      { id: 'webinar', logo: null },
    ];
    if (partnerId) {
      const index = partners.findIndex(p => p.id === partnerId);

      if (index < 0) {
        return null;
      }

      const foundPartner = partners[index];
      setPartner(foundPartner);
    }
  }, [partnerId]);

  const goToCanvas = project => history.push(`/canvas/${project.code}`);
  const clearRecentlyViewedProject = () => {
    // @ts-ignore
    toastedAnalytics.emit('clearRecentlyViewedProject');

    localStorage.setItem('projects', '[]');
    refetch({ ids: [] });
  };

  const { cards } = props;

  const list = {
    visible: {
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.2,
      },
    },
    hidden: {
      transition: {
        when: 'afterChildren',
      },
    },
  };

  const item = {
    visible: { opacity: 1, x: 0, scale: 1 },
    hidden: { opacity: 0, x: -10, scale: 0.3 },
  };

  return (
    <div className="flex flex-col justify-between text-center">
      {partner && partner.logo && (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={item}
          className="text-center flex justify-center mb-8"
        >
          <div className="w-48 rounded shadow-xl bg-white">
            <img alt="partnerLogo" src={partner.logo && partner.logo} />
          </div>
        </motion.div>
      )}
      <div className="mb-8">
        <h2 className="text-center text-2xl font-extrabold text-teal-900 tracking-tight">
          Canvas Creator{' '}
          <span className="text-teal-600 text-xs align-top align-text-top font-extrabold">
            beta
          </span>
        </h2>
        <p className="text-center text-lg text-gray-600 tracking-tight">
          Free to use business model canvas creator.
        </p>
      </div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={list}
        className="w-full text-center flex justify-center flex-wrap"
      >
        {cards.map(card => (
          <HomeCard key={card.title} partnerId={partnerId} {...card} />
        ))}
      </motion.div>
      {!loading && !error && projects && projects.length > 0 && (
        <div className="w-full justify-center flex">
          <div className="w-1/2 mt-8">
            <h3 className="text-xl text-gray-700 font-bold mb-2 mt-8">
              Recently Viewed Projects:
            </h3>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={list}
              className="flex flex-wrap justify-center"
            >
              {projects.map(project => (
                <motion.div
                  key={project._id}
                  variants={item}
                  className="max-w-sm my-2 mx-2"
                >
                  <Button onClick={() => goToCanvas(project)}>
                    {project.name}
                  </Button>
                </motion.div>
              ))}
            </motion.div>
            <Button type="primary" onClick={() => clearRecentlyViewedProject()}>
              Clear List
            </Button>
          </div>
        </div>
      )}
      <div></div>
    </div>
  );
};

export default App;
