import React from 'react';
import { RIEInput, RIETextArea } from 'riek';
import _ from 'underscore';
import { useMutation } from '@apollo/react-hooks';
import Loader from 'react-loader-spinner';
import { loader } from 'graphql.macro';

interface ITitleProps {
  _id: string;
  title: string;
  description?: string;
  color?: string;
  disabled?: boolean;
}

interface IItem {
  name?: string;
  description?: string;
}

const UPDATE_PROJECT = loader('../mutations/Project/UPDATE_BY_ID.graphql');

const Title = (props: ITitleProps) => {
  const [updateProject] = useMutation(UPDATE_PROJECT);

  const { color } = props;
  const update = (item: IItem) => {
    const record = { ...item, _id: props._id };
    try {
      updateProject({ variables: { record } });
    } catch (e) {
      console.log('e', e);
    }
  };

  return (
    <div className="flex justify-center lg:justify-start text-center">
      <div className="m-2">
        <h2 className="text-2xl text-gray-700 font-bold mb-4">
          <span className={`text-${color}-500`}>[ </span>
          <RIEInput
            disabled={props.disabled || false}
            value={props.title}
            change={update}
            propName="name"
            className={'text-gray-100'}
            classInvalid="text-red-500"
            classEditing="bg-teal-700 font-bold text-center text-gray-100"
            validate={_.isString}
          />

          <span className={`text-${color}-500`}> ]</span>
        </h2>
        <h4 className="text-lg text-gray-600 max-w-xl tracking-wider">
          <RIETextArea
            disabled={props.disabled || false}
            rows={5}
            columns={10}
            placeholder="Write your best pitch here"
            value={props.description || 'Write your best pitch here'}
            change={update}
            propName="description"
            className={'text-gray-100'}
            classInvalid="text-red-500"
            classEditing="text-center w-full outline:none bg-teal-700 text-white"
            validate={_.isString}
          />
        </h4>
      </div>
    </div>
  );
};

Title.defaultProps = {
  description: '',
  title: '',
  color: 'teal',
};

export default Title;
