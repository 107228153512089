import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ApolloProvider } from '@apollo/react-hooks';

import CanvasScene from 'scenes/CanvasScene';
import HomeScene from 'scenes/HomeScene';
import NotFound from 'scenes/NotFound';

import MainLayout from 'components/MainLayout';
import { client } from './Apollo';
import WizardScene from 'scenes/CanvasScene/WizardScene';

const homeCards = [
  {
    title: 'New Project',
    description:
      'Get started building your own business model canvas by enter a project name below.',
    placeholder: 'Project Name',
    autoFocus: true,
    img: 'images/bmc.svg',
  },
  {
    title: 'Already have a code?',
    description:
      'Simply enter the code in the box below to see the business model canvas.',
    placeholder: 'Code',
    hasCode: true,
    img: 'images/code.svg',
  },
];

const workshopCards = [
  {
    title: 'Join the workshop',
    description:
      'The course instructor should provide you with a code to enter here',
    placeholder: 'Workshop Code',
    hasCode: true,
    autoFocus: true,
    img: 'images/group.svg',
    isWorkshop: true,
  },
];

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <MainLayout>
          <Switch>
            <Route
              exact
              path="/"
              render={() => <HomeScene cards={homeCards} />}
            />
            <Route path="/canvas/:id" exact component={CanvasScene} />
            <Route path="/notfound" exact component={NotFound} />
            <Route
              exact
              path="/:partnerId"
              render={() => <HomeScene cards={homeCards} />}
            />
          </Switch>
        </MainLayout>
      </Router>
    </ApolloProvider>
  );
};

export default App;
