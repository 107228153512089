import React from 'react';

import Nav from './Nav';

import { Link } from 'react-router-dom';

const MainLayout = props => {
  return (
    <div className="min-h-screen h-full bg-gray-200">
      <Nav />
      <div className="flex flex-col justify-center xl:justify-start bg-gray-200 min-h-screen p-5 lg:p-10 xl:p-10 h-full text-center">
        {props.children}
        <div className="flex justify-center">
          <p className="m-32 font-bold text-xs">
            Made with{' '}
            <span role="img" aria-label="love" className="text-red-500">
              ♥️
            </span>{' '}
            by <span>Mike Rudge</span>
            <br />
            <a className="text-blue-500 ml-4" href="/terms.html">
              Terms and Conditions
            </a>
            <a className="text-blue-500 ml-4" href="/privacy.html">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

const Wrapped = React.memo(MainLayout);

export default Wrapped;
