import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiCopy } from 'react-icons/fi';
import Button from './Button';

const links = [
  // { href: "/projects", label: "Projects" },
  { href: '/problem', label: 'Define the Problem' },
  { href: '/canvas', label: 'Business Model Canvas' },
  // { href: "/personas", label: "Personas" },
  { href: '/stories', label: 'User Stories' },
];

const NavItem = props => {
  return (
    <a
      href={props.href}
      className={`${props.index > 0 &&
        'ml-10'} text-sm font-medium text-gray-900 hover:text-gray-700`}
    >
      {props.label}
    </a>
  );
};

const Nav = props => {
  return (
    <nav className="relative flex flex-wrap items-center justify-between md:py-4 bg-white px-4 fixed">
      <div className="relative z-10 flex-shrink-0 pl-4 py-4 md:p-0">
        {/* <img className="h-8 w-8" src="/img/example-logo.svg" alt="" /> */}
        <h5 className="text-gray-700 font-bold">
          Canvas Creator{' '}
          <span className="text-teal-600 text-xs align-top align-text-top font-extrabold">
            beta
          </span>
        </h5>
      </div>

      <div className="hidden md:block md:ml-10 md:flex md:items-baseline md:justify-between md:bg-transparent">
        <div className="lg:absolute inset-0 flex items-center justify-center">
          <div className="max-w-sm xl:max-w-2xl m-2">
            {/* <Button icon={<FiCopy />}>Become a sponser</Button> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
