import React, { ReactNode, useState } from 'react';

import { motion } from 'framer-motion';
import { RIEInput, RIETextArea } from 'riek';
import { gql, DocumentNode } from 'apollo-boost';

const item = {
  hidden: { opacity: 0, x: -30 },
  visible: { opacity: 1, x: 0, beforeChildren: true, delay: 100 },
};

interface IEditableCell {
  name: string;
  value: string;
}

interface ICardProps {
  key?: string;
  _id?: string;
  imagePath?: string;
  name?: string;
  description?: string;
  children?: ReactNode;
  dbKey?: string;
  isEditable?: boolean;
  style?: object;
  query?: DocumentNode;
  onUpdate?: (options: IEditableCell) => void;
  accessory?: ReactNode | string;
  header?: boolean;
}

const itemCard = {
  visible: { opacity: 1, x: 0, scale: 1 },
  hidden: { opacity: 0, x: -50, scale: 0.97 },
};
const Card = (props: ICardProps) => {
  const update = (options: IEditableCell) => {
    if (props.onUpdate) {
      props.onUpdate(options);
    }
  };

  const { name, description } = props;

  return (
    <div>
      <motion.li
        // positionTransition
        // variants={item}
        className={`flex flex-col justify-between transition-all transition-500 transition-ease max-w-sm rounded shadow-2xl bg-white xl:m-5 lg:m-4 md:m-3 m-2 pt-8`}
        style={{
          width: '24rem',
          ...props.style,
        }}
      >
        <div>
          {props.imagePath && (
            <div className="flex justify-center h-48">
              <img className="w-full" src={props.imagePath} />
            </div>
          )}
          <div className="flex flex-wrap px-8 py-2 text-center xl:text-left ">
            <div className="flex w-full">
              {props.name &&
                (props.isEditable ? (
                  <RIEInput
                    value={name}
                    change={update}
                    propName="name"
                    className="cursor-pointer font-bold text-xl text-gray-800 w-full"
                    classLoading="text-gray-400"
                    classInvalid="invalid"
                    isDisabled={!props.isEditable}
                  />
                ) : (
                  <div className="font-bold text-xl text-gray-800 w-full my-2">
                    {props.name}
                  </div>
                ))}
              {props.accessory && props.accessory}
            </div>
            {props.description && props.isEditable ? (
              <RIETextArea
                value={description}
                change={update}
                propName="description"
                classEditing="text-gray-600 bg-transparent w-full min-h-full h-40 text-base  tracking-wide xl:leading-relaxed"
                className="text-gray-600 w-100 cursor-pointer transition-all transition-500 transition-ease tracking-wide xl:leading-relaxed"
              />
            ) : (
              <p
                className={
                  'text-gray-600 text-base transition-all transition-500 transition-ease tracking-wide xl:leading-relaxed'
                }
              >
                {props.description}
              </p>
            )}
          </div>
        </div>
        <div className="mt-4 px-2 py-2 lg:py-4 lg:px-4 xl:py-4 xl:px-4">
          {props.children}
        </div>
      </motion.li>
    </div>
  );
};

Card.defaultProps = {
  isEditable: false,
  header: false,
};

export default Card;
